import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ApiUrl } from '../../components/API/Api';
import { CircularProgress, Typography, Paper, Grid } from '@mui/material';

const HolyFatherMessage = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const condition = localStorage.getItem("Pages");
            if (condition) {
              setData(JSON.parse(condition));
              setLoading(false);
            } 
              const response = await axios.get(`${ApiUrl}/get/Pages`);
              const newData = response?.data?.data;
              const exixtingdate = localStorage.getItem("Pages");
              if (newData !== JSON.stringify(exixtingdate)) {
                localStorage.removeItem("Pages");
                localStorage.setItem("Pages", JSON.stringify(newData));
                setData(newData);
              }
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);

    const filteredData = data?.filter(item => item.id === 36);

    return (
        <div className="container">

            {loading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />}

            {!loading && filteredData.length === 0 && (
                <Typography variant="h6" sx={{ marginTop: 3 }}><b>No Data Available</b></Typography>
            )}

            {!loading && filteredData.length > 0 && (
                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                    {filteredData.map(item => (
                        <Grid item xs={12} key={item.id}>
                            <Paper elevation={3} sx={{ padding: 3, textAlign: 'center' }}>
                                <Typography variant="h4" gutterBottom>{item.title}</Typography>
                                {item.image && (
                                    <img src={item.image} alt={item.title} style={{ width: '100%', maxHeight: 300, objectFit: 'cover' }} />
                                )}
                                <Typography variant="body1" sx={{ marginTop: 2 }}>{item.description}</Typography>
                                <div dangerouslySetInnerHTML={{ __html: item.content }} sx={{ marginTop: 2 }} />
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );
};

export default HolyFatherMessage;
