import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  margin-right: 20px;
  width: 100px;
  height: 100px;
`;

const ProfileInfo = styled.div`
  flex: 1;
`;

const Title = styled.h2`
  margin: 0;
  font-size: 24px;
`;

const Subtitle = styled.h3`
  margin: 5px 0 0 0;
  font-size: 18px;
  color: #555;
`;

const InfoSection = styled.div`
  margin-bottom: 20px;
`;

const InfoLabel = styled.div`
  font-weight: bold;
`;

const InfoValue = styled.div`
  margin-bottom: 5px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  background-color: #f4f4f4;
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;

const BishopProfile = () => {
  return (
    <Container>
      <ProfileHeader>
        <ProfileImage src="images/all-img/bishop1.jpg" alt="Bishop" />
        <ProfileInfo>
          <Title>Most Rev. Dr. A. NEETHINATHAN</Title>
          <Subtitle>Bishop of Chingleput</Subtitle>
        </ProfileInfo>
      </ProfileHeader>

      <InfoSection>
        <InfoLabel>Date of Birth:</InfoLabel>
        <InfoValue>03-07-1955</InfoValue>
        <InfoLabel>Place of Birth:</InfoLabel>
        <InfoValue>Kakkanur, Villupuram Taluk, Tamil Nadu</InfoValue>
        <InfoLabel>Ordination Date:</InfoLabel>
        <InfoValue>09-05-1987</InfoValue>
        <InfoLabel>Bishop Ordination Date:</InfoLabel>
        <InfoValue>19-07-2002</InfoValue>
        <InfoLabel>Email:</InfoLabel>
        <InfoValue>chengaidiocese@yahoo.co.in</InfoValue>
        <InfoLabel>Address:</InfoLabel>
        <InfoValue>
          Catholic Bishop's House, Thimmapuram, Kancheepuram Road, Chennai, Tamil Nadu, India, 603 101
        </InfoValue>
        <InfoLabel>Role:</InfoLabel>
        <InfoValue>Bishop</InfoValue>
        <InfoLabel>Blood Group:</InfoLabel>
        <InfoValue></InfoValue>
      </InfoSection>

      <Table>
        <thead>
          <tr>
            <TableHeader>S.No</TableHeader>
            <TableHeader>Parish Name</TableHeader>
            <TableHeader>Date From</TableHeader>
            <TableHeader>Date To</TableHeader>
            <TableHeader>Role</TableHeader>
            <TableHeader>Status</TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>1</TableCell>
            <TableCell>Catholic Bishop's House</TableCell>
            <TableCell>29-09-2002</TableCell>
            <TableCell></TableCell>
            <TableCell>Bishop</TableCell>
            <TableCell>Active</TableCell>
          </tr>
          <tr>
            <TableCell>2</TableCell>
            <TableCell>Thimmapuram</TableCell>
            <TableCell>01-01-1987</TableCell>
            <TableCell>31-12-1991</TableCell>
            <TableCell>Asst. Director, Director, Vocation Promoter</TableCell>
            <TableCell>Completed</TableCell>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default BishopProfile;
