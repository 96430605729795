import axios from "axios";
import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { ApiUrl } from "../../components/API/Api";
import { CircularProgress } from "@mui/material";

import DataTable from "react-data-table-component";

const ReligiousData = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [categoryName, setCategoryName] = useState("Religious Details");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const searchParams = new URLSearchParams(location?.search);
  const CategoryFromUrlId = searchParams.get("from");
  const [filterddata, Filterdata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${ApiUrl}/get/chengaidiocese/religio/${parseInt(CategoryFromUrlId)}`
        );
        const responseData = response?.data;
        setData(responseData?.data || []);
        Filterdata(responseData?.data || []);
        if (responseData?.data?.length > 0) {
          setCategoryName(responseData.data[0].category_name);
        }
      } catch (error) {
        setError(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [CategoryFromUrlId]);
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "70px",
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${row.image === null
            ? "images/all-img/Catholic-Priest.png"
            : row.image
            }`}
          alt="Parish"
          style={{ width: "80px", height: "87px" }}
        />
      ),
      width: "100px",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        backgroundColor: "#fafafa",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "15px",
        fontFamily: "Montserrat",
        backgroundColor: "#912849",
        color: "white",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontFamily: "Montserrat",
        fontSize: "14px",
      },
    },
    pagination: {
      style: {
        fontWeight: "700",
        color: "black",
        fontFamily: "Montserrat",
      },
    },
  };
  function filterdata(event) {
    var value = event.target.value;
    const keys = [
      "name",
    ];
    const filter = filterddata?.filter((item) =>
      keys.some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );

    setData(filter);
  }
  return (
    <>
      <div className="container">
        {!loading && !error && (
          <>
            {data.length === 0 ? (
              <div className="center-message">
                <p>No Data Available</p>
              </div>
            ) : (
              <>
                <h3 className="heading">{categoryName}</h3>
                <div className="row mb-4">
                    <div className="col-lg-12">
                      <input
                        id="myInput"
                        type="text"
                        onChange={filterdata}
                        className="form-control myInput"
                        placeholder="Search.."
                      />
                    </div>
                  </div>
                   <DataTable
                    columns={columns}
                    theme="solarized"
                    data={data}
                    pagination
                    progressPending={loading}
                    customStyles={customStyles}
                    paginationPerPage={10} 
                  />
              </>
            )}
          </>
        )}
        {loading && (
          <CircularProgress
            sx={{ display: "block", margin: "auto", marginTop: "2rem" }}
          />
        )}
        {error && (
          <div className="center-message">
            <p>{error}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default ReligiousData;
