import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ApiUrl } from '../../components/API/Api';
import { CircularProgress } from '@mui/material';

const Monthlyprogramme = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const condition = localStorage.getItem("Pages");
            if (condition) {
              setData(JSON.parse(condition));
              setLoading(false);
            } 
              const response = await axios.get(`${ApiUrl}/get/Pages`);
              const newData = response?.data?.data;
              const exixtingdate = localStorage.getItem("Pages");
              if (newData !== JSON.stringify(exixtingdate)) {
                localStorage.removeItem("Pages");
                localStorage.setItem("Pages", JSON.stringify(newData));
                setData(newData);
              }
          } catch (error) {
            console.error("Error fetching data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, []);

    const filteredData = data.filter(item => item.id === 4);

    return (
        <div className="container mt-5">

            {loading && <CircularProgress sx={{ display: 'block', margin: 'auto' }} />}

            {!loading && filteredData.length === 0 && <h6><b>No Data Available</b></h6>}

            {!loading && filteredData.length > 0 && (
                <div className="custom-layout mt-3">
                    {filteredData.map(item => (
                        <div key={item.id} className="custom-item">
                            <div dangerouslySetInnerHTML={{ __html: item.content }} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Monthlyprogramme;
