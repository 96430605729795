import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ListGroup, Row, Col } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { ReligioUrl } from '../../components/API/Api';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

const EventCard = styled(ListGroup.Item)`
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  animation: ${keyframes`
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `} 0.5s ease-in-out;
`;

const Title = styled.h5`
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 18px !important;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Date = styled.small`
  color: #6c757d;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Content = styled.p`
  margin-top: 0.5rem;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Link = styled.p`
  margin-top: 0.5rem;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const EventImage = styled.img`
  height: 200px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media (max-width: 768px) {
    height: 150px;
  }
`;

const EventDetails = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
`;

const goBack = () => {
    window.history.back();
};

const LatestEvents = () => {
    const [events, setEvents] = useState(null);
    const search = useLocation().search;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const eventID = new URLSearchParams(search).get("newsid");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ReligioUrl}/news/562`);
                const data = response?.data?.data;
                if (data && eventID) {
                    const foundNews = data?.find(item => item?.id === parseInt(eventID));
                    setEvents([foundNews]);
                } else {
                    setEvents(data);
                }
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [eventID]);

    if (error) {
        return <div>Error: {error?.message}</div>;
    }

    if (loading) {
        return <div><center><p className="mt-5"><CircularProgress /></p></center></div>;
    }

    if (!events || events.length === 0) {
        return <div><center><p className="mt-5">No data available</p></center></div>;
    }

    return (
        <Container className="mt-3">
            <h2 className="text-center">Latest Events</h2>
            <ListGroup>
                {events?.map((event) => (
                    <EventCard key={event?.id}>
                        <Row>
                            <Col md={8}>
                                <EventDetails>
                                    <Title><i className='fa fa-ticket'></i>&nbsp;&nbsp;&nbsp;{event?.name}</Title>
                                    <br />
                                    <Date><i className='fa fa-calendar-alt'></i>&nbsp;&nbsp;&nbsp;{event?.date}</Date>
                                    <br />
                                    <Link>
                                        {event?.file && (
                                            <>
                                                <i className='fa fa-file-pdf'></i>&nbsp;&nbsp;&nbsp;
                                                <a href={event?.file} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "blue" }}>
                                                    {event?.file}
                                                </a>
                                            </>
                                        )}
                                    </Link>
                                    <Content>
                                        <i className='fa fa-info-circle'></i>&nbsp;&nbsp;&nbsp;<p dangerouslySetInnerHTML={{ __html: event?.description }} style={{ textAlign: "justify" }} />
                                    </Content>
                                </EventDetails>
                            </Col>
                            <Col md={4} className="d-flex justify-content-center">
                                <EventImage src={event?.image} alt='events' />
                            </Col>
                        </Row>
                    </EventCard>
                ))}
            </ListGroup>
            <br />
            <center><button onClick={goBack} className='btn btn-primary btn-sm'>Go Back</button></center>
        </Container>
    );
};

export default LatestEvents;
