import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ReligioUrl } from "../../components/API/Api";

const TransferList = () => {
    const [newsletterData, setNewsletterData] = useState([]);

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/circular/562`)
            .then((response) => {
                const allData = response?.data?.data;
                allData.sort((a, b) => new Date(b.id) - new Date(a.id));
                const latestData = allData.slice(0, 5);
                setNewsletterData(latestData);
                console.log(latestData);
            })
            .catch((error) => {
                console.error("Error fetching newsletter data:", error);
            });
    }, []);
    const getMonthName = (monthNumber) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        return monthNames[monthNumber - 1];
    };

    return (
        <>
            <center>
                <div className="col-md-12 container">
                    <div className="section-heading">
                        <h2 className="entry-titles mt-3 mb-3">Transfer List</h2>
                    </div>
                    <div className="scrollable-content" id="newsletterprog">
                        {newsletterData.length > 0 ? (
                            newsletterData.map((newsletter) => {
                                const { id, name, month, year, upload } = newsletter;
                                const monthName = getMonthName(parseInt(month));
                                return (
                                    <div
                                        key={id}
                                        className="card shadow mb-2"
                                        style={{ maxWidth: "585px", height: "100px" }}
                                    >
                                        <div className="card-body text-center">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <img
                                                    src="images/all-img/pdf.png"
                                                    alt="PDF Icon"
                                                    className="mb-3"
                                                    style={{ width: "50px" }}
                                                />
                                                <div className="text-center">
                                                    <h5 className="card-title mb-2">{name}</h5>
                                                    <p className="card-text mb-3">{`${monthName} ${year}`}</p>
                                                </div>
                                                <a
                                                    href={upload}
                                                    className="btn btn-sm btn-primary"
                                                    download
                                                >
                                                    Download
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>
                                <b>No Newsletter available</b>
                            </p>
                        )}

                        {newsletterData.length >= 5 && (
                            <center>
                                <Link
                                    to={"/newsletter"}
                                    style={{
                                        backgroundColor: "#012c6d",
                                        color: "white",
                                        textDecoration: "none",
                                        display: "inline-block",
                                        padding: "8px 16px",
                                        borderRadius: "5px",
                                        marginTop: "10px",
                                        fontSize: "14px",
                                    }}
                                >
                                    View More
                                </Link>
                            </center>
                        )}
                    </div>
                </div>
            </center>
        </>
    )
}

export default TransferList
