import React, { useEffect, useState } from 'react'
import './PastoralNews.css'
import { ReligioUrl } from '../../components/API/Api';
import axios from 'axios';
const PastoralNews = () => {
    const [pastroalnews, setPastroalnews] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${ReligioUrl}/news/562`);
                setPastroalnews(response?.data?.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <h3 className="heading">Latest News</h3>
            <div className="past-container">
                {
                    pastroalnews?.map((project, index) => (
                        <div className="past" key={index}>
                            <img
                                src={project?.image}
                                alt={project?.name}
                            />
                            <div className="past-content">
                                <h2><i className='fa fa-ticket'></i>&nbsp;&nbsp;{project?.name}</h2>
                                <p className='mt-3'><i className='fa fa-calendar'></i>&nbsp;&nbsp;{project?.date}</p>
                                <i className='fa fa-link'></i>&nbsp;&nbsp;<a href={project?.file} target="_blank" rel="noopener noreferrer" className="button">{project?.file}</a>
                                <button className="btn btn-primary btn-sm mt-3" onClick={() => window.location.href = `latestevents?newsid=${project?.id}`}>Read More</button>
                            </div>
                        </div>
                    )) || <p>Loading...</p>
                }
            </div>

        </>
    )
}

export default PastoralNews
