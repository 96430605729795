import React from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./layout";
import Home from "./home";
import History from "./pages/AboutUs/history";
import DiocesanPriests from "./pages/Priests/DiocesanPriests";
import Contact from "./pages/Contact/Contact";
import UpcomingEvents from "./pages/Events/UpcomingEvents";
import LatestEvents from "./pages/Events/LatestEvents";
import Donate from "./pages/Donation/Donate";
import AllPriestDetails from "./pages/Priests/PriestAllDetails/AllPriestDetails";
import Instituation from "./pages/Institution/instituation";
import BishopMessage from "./pages/Bishop/BishopMessage";
import NewsLetter from "./pages/Bishop/NewsLetter";
import HolyFatherMessage from "./pages/Bishop/HolyFatherMessage";
import Monthlyprogramme from "./pages/Bishop/MonthlyProgram";
// import BishopSpeak from "./pages/AboutUs/BishopSpeak";
import BishopProfile from "./pages/AboutUs/BishopProfile";
import PastoralCenter from "./pages/PastoralCenter/PastoralCenter";
import Administration from "./pages/administration/administration";
import TransferList from "./pages/Priests/TransferList";
import ReligiousData from "./pages/Religious/ReligiousData";
import Prieststudies from "./pages/Priests/Prieststudies";
//import Diocese from "./pages/Priests/PriestAllDetails/diocese";
import Projects from "./pages/projects/Projects";
import Vicariates from "./pages/Parishes/Vicariates";
import NewParishesDetails from "./pages/Parishes/AllParishDetails/NewPairshesDetails";
import ReligiousBrothers from "./pages/Religious/ReligiousBrothers";
import Youtube from "./pages/Gallery/Youtube";
import ParishStatistics from "./pages/Parishes/ParishStatistics";
import NotFoundPage from './errors/NotFoundPage';
import FlashNews from "./pages/Events/FlashNews";
// import ParishShrines from "./pages/Parishes/ParishShrines";
import AllStudyPriestDetails from "./pages/Priests/PriestAllDetails/AllStudyPriestDetails";
// import SpecialMinistry from "./pages/Priests/SpecialMinistry";
import AllSpecialministry from "./pages/Priests/PriestAllDetails/AllSpecialministry";
import PastoralNews from "./pages/PastoralCenter/PastoralNews";
import Overseas from "./pages/Priests/Overseas";
import AllOverseas from "./pages/Priests/PriestAllDetails/AllOverseas";
import DiocesanCuria from "./pages/AboutUs/DiocesanCuria";
// import BishopCalendar from "./pages/AboutUs/BishopCalendar";
import ParishData from "./pages/Parishes/ParishData";
// import ReligiousPriest from "./pages/Religious/ReligiousPriest";
import AllReligiousPriest from "./pages/Religious/AllDetails/AllReligiouPriest";
import Commissions from "./pages/PastoralCenter/Commissions";
import CouncilMembers from "./pages/administration/Council";
import Committee from "./pages/administration/Committee";
import Retired from "./pages/Priests/Retired";
import Leave from "./pages/Priests/Leave";
//import ParishesNew from "./pages/Parishes/parishesnew";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<MainLayout />}>
          {/* About us Section Routing */}

          <Route path="aboutus" element={<History />} />
          <Route path="history" element={<History />} />
          {/* <Route path="bishop-speak" element={<BishopSpeak />} /> */}
          <Route path="bishop-speak" element={<BishopMessage />} />
          <Route path="bishop-profile" element={<BishopProfile />} />
          {/* <Route path="bishop-calendar" element={<BishopCalendar />} /> */}
          {/* <Route path="diocesan-curia" element={<History/>} /> */}
          <Route path="diocesan-pastoral" element={<History />} />
          <Route path="telephone" element={<History />} />
          <Route path="our-shepherd" element={<BishopProfile />} />
          <Route path="diocesan-curia" element={<DiocesanCuria/>} />

          {/* Administration Section Routing */}

          <Route path="tribunal" element={<Administration />} />
          <Route path="finance" element={<Administration />} />
          <Route path="judicial-vicar" element={<Administration />} />
          <Route path="tribunal-activity" element={<Administration />} />
          <Route path="college-consult" element={<Administration />} />
          <Route path="diocesan-priest-council" element={<Administration />} />
          {/* <Route
            path="diocesan-project-committee"
            element={<Committee />}
          /> */}
          {/* <Route
            path="diocesan-property-committee"
            element={<Administration />}
          />
          <Route
            path="diocesan-finance-committee"
            element={<Administration />}
          /> */}
          <Route path="council-pastroal" element={<CouncilMembers />} />
          {/* <Route path="council-formation" element={<Administration />} /> */}
          <Route path="diocesan-society" element={<Administration />} />
          <Route
            path="chingleput-education-society"
            element={<Administration />}
          />
          <Route path="chingleput-development" element={<Administration />} />
          <Route path="councils" element={<Administration />} />
          <Route path="committe" element={<Committee />} />
          <Route path="administration" element={<Administration />} />

          {/* Pastoral Center Section Page Routing */}

          <Route path="pastoral-centre" element={<PastoralCenter />} />
          <Route path="pastoral-commission" element={<Commissions />} />
          <Route path="pastoral-news" element={<PastoralNews />} />
          <Route path="special-events" element={<PastoralCenter />} />
          <Route path="retreats" element={<PastoralCenter />} />
          <Route path="recollections" element={<PastoralCenter />} />

          {/* Parish Section Routing Page */}
          <Route path="vicariates" element={<Vicariates />} />
          <Route path="parishes" element={<ParishData />} />
          {/* <Route path="parishes" element={<ParishesNew />} /> */}
          <Route path="parishmoredetails" element={<NewParishesDetails />} />
          <Route path="parish-statistics" element={<ParishStatistics />} />
          <Route path="shrines" element={<ParishStatistics />} /> 

          {/* <Route path="shrines" element={<ParishShrines />} /> */}

          {/* Priest Section Routing Page */}

          <Route path="diocesan-priests" element={<DiocesanPriests />} />
          {/* <Route path="priests" element={<DiocesanPriests />} /> */}

          <Route path="priestmoredetails" element={<AllPriestDetails />} />
          {/* <Route path="diocesan-priests" element={<Diocese />} /> */}

          <Route path="transfer-list" element={<TransferList />} />
          <Route path="studies" element={<Prieststudies />} />
          <Route path="studymoredetails" element={<AllStudyPriestDetails />} />
          {/* <Route path="special-ministries" element={<SpecialMinistry />} /> */}
          <Route path="specialministrydetails" element={<AllSpecialministry />} />
          <Route path="overseas" element={<Overseas />} />
          <Route path="alloverseas" element={<AllOverseas />} />
          <Route path="leave" element={<Leave />} />
          <Route path="retired-priest" element={<Retired />} />

          {/* Religious Section Routing Page */}
          <Route path="religious-priest" element={<ReligiousData />} />
          <Route path="allreligiouspriest" element={<AllReligiousPriest />} />
          <Route path="religious-sisters" element={<ReligiousData />} />
          <Route path="religious-brothers" element={<ReligiousBrothers />} />
          <Route path="religious" element={<ReligiousBrothers />} />

          {/* Youtube Section Routing Page */}
          <Route path="youtube" element={<Youtube />} />

          {/* Institution Section Routing Page */}
          <Route path="institutions" element={<Instituation />} />
          <Route path="jias" element={<Instituation />} />
          <Route path="schools" element={<Instituation />} />
          <Route path="primaryschool" element={<Instituation />} />
          <Route path="middle-tamil" element={<Instituation />} />
          <Route path="high-tamil" element={<Instituation />} />
          <Route path="high-sec-tamil" element={<Instituation />} />
          <Route path="matric-sec-school" element={<Instituation />} />
          <Route path="nursery-primary-schools" element={<Instituation />} />
          <Route path="cbse-schools" element={<Instituation />} />
          <Route path="religious-primary-school" element={<Instituation />} />
          <Route path="religious-middle-tamil" element={<Instituation />} />
          <Route path="religious-high-tamil" element={<Instituation />} />
          <Route path="special-ministries" element={<Instituation />} />
          <Route path="diocesan-schools" element={<Instituation />} />
          <Route path="religious-schools" element={<Instituation />} />
          <Route path="children-home-orphanages" element={<Instituation />} />
          <Route path="old-age-home" element={<Instituation />} />
          <Route path="obituary" element={<Instituation />} />

          <Route
            path="religious-high-sec-school-tamil"
            element={<Instituation />}
          />
          <Route path="religious-mat-high-school" element={<Instituation />} />
          <Route path="religious-nursery-primary" element={<Instituation />} />
          <Route path="religious-cbse-school" element={<Instituation />} />
          <Route path="colleges" element={<Instituation />} />
          <Route path="college-diocesan" element={<Instituation />} />
          <Route path="iti-polytechnics" element={<Instituation />} />
          <Route path="religious-colleges" element={<Instituation />} />
          <Route path="institute-poly-diocesan" element={<Instituation />} />
          <Route path="institute-poly-religious" element={<Instituation />} />
          <Route path="st-joseph-minor-seminary" element={<Instituation />} />
          <Route path="seminarian-institution" element={<Instituation />} />
          <Route path="seminarians-fund" element={<Instituation />} />
          <Route path="home-diocesan" element={<Instituation />} />
          <Route path="home-religious" element={<Instituation />} />
          <Route path="old-age-diocesan" element={<Instituation />} />
          <Route path="old-age-religious" element={<Instituation />} />

          {/* projects section page routing */}
          <Route path="projects" element={<Projects />} />
          <Route path="diocesan-projects" element={<Projects />} />
          <Route path="ongoing-projects" element={<Projects />} />
          <Route path="evangelisation" element={<Projects />} />
          <Route path="educational" element={<Projects />} />
          <Route path="social-project" element={<Projects />} />

          {/* Donate Section Page Routing */}

          <Route path="donation" element={<Donate />} />

          {/* Contact Us Section Page Routing */}

          <Route path="contactus" element={<Contact />} />

          {/* Events Page Routing */}
          <Route path="upcomeevents" element={<UpcomingEvents />} />
          <Route path="latestevents" element={<LatestEvents />} />
          <Route path="flash-news-data" element={<FlashNews />} />

          {/* Bishop Section Page Routing */}
          {/* <Route path="bishopmessage" element={<BishopMessage />} /> */}
          <Route path="newsletter" element={<NewsLetter />} />
          <Route path="holyfathermessage" element={<HolyFatherMessage />} />
          <Route path="bishop-calendar" element={<Monthlyprogramme />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
