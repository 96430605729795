import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { ReligioUrl } from "../../components/API/Api";
import axios from "axios";

const DiocesanCuria = () => {
    const [data, Setdata] = useState([]);
    const [filterddata, Filterdata] = useState([]);

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/member_list/diocese_curia/562`)
            .then((response) => {
                Setdata(response?.data?.data);
                Filterdata(response?.data?.data);
                console.log(response?.data?.data);
                
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: "70px",
            sortable: true,
        },
        {
            name: "Image",
            cell: (row) => (
                <img
                    src={`${row.image === null
                        ? "images/all-img/Catholic-Priest.png"
                        : row.image
                        }`}
                    alt="Priest"
                    style={{ width: "80px", height: "87px", marginTop: "10px", marginBottom: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",borderRadius: "10px" }}
                />
            ),
        },
        {
            name: "Name",
            selector: (row) => row.member_name || "-",
            sortable: true,
            width: "300px",
        },
        {
            name: "Roles",
            cell: (row) => (
                <>
                    <span className="badge badge-pill bg-success me-1">
                        {row.role_ids || "-"}
                    </span>
                </>
            ),
            sortable: true,
        },
        {
            name: "Date From",
            selector: (row) => row.date_from || "-",
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.status || "-",
            sortable: true,
        }
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "52px",
                backgroundColor: "#fafafa",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "15px",
                fontFamily: "Montserrat",
                backgroundColor: "#912849",
                color: "white",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                fontFamily: "Montserrat",
                fontSize: "14px",
            },
        },
        pagination: {
            style: {
                fontWeight: "700",
                color: "black",
                fontFamily: "Montserrat",
            },
        },
    };
    function filterdata(event) {
        var value = event.target.value;
        const keys = [
            "member_name",
            "date_from",
            "place",
            "purpose",
            "status",
        ];
        const filter = filterddata?.filter((item) =>
            keys.some((key) =>
                item[key]?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
            )
        );

        Setdata(filter);
    }

    const [pending, setPending] = React.useState(true);

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setPending(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <div className="container">
            <div className="page-header">
                <h2 class="heading">Diocesan Curia</h2>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <input
                        id="myInput"
                        type="text"
                        onChange={filterdata}
                        className="form-control myInput"
                        placeholder="Search..."
                    />
                </div>
            </div>
            <br></br>
            <div className="row">
                <div className="col-lg-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <DataTable
                                columns={columns}
                                theme="solarized"
                                data={data}
                                pagination
                                progressPending={pending}
                                customStyles={customStyles}
                                highlightOnHover
                                pointerOnHover
                                noDataComponent="No Data Found"
                                noHeader
                                responsive
                                striped
                                paginationRowsPerPageOptions={[10, 30, 60, 90, 120, 500]}
                                paginationPerPage={10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiocesanCuria;