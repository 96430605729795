import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { ApiUrl } from '../../components/API/Api';

const EventCard = styled(ListGroup.Item)`
  list-style-type: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
`;

const Title = styled.h5`
  margin-bottom: 0.5rem;
`;

const Date = styled.small`
  color: #6c757d;
`;

const Content = styled.p`
  margin-top: 0.5rem;
`;

const Link = styled.p`
  margin-top: 0.5rem;
`;

const goBack = () => {
    window.history.back();
};

const FlashNews = () => {
    const [flashnews, setFlashNews] = useState([]);

    useEffect(() => {
        axios
            .get(`${ApiUrl}/resource/category/1`)
            .then((res) => {
                const sortedEvents = res.data.data.sort((a, b) => {
                    // Sort by descending order of ID to show latest ID data on top
                    return b.id - a.id;
                });
                setFlashNews(sortedEvents);
            });
    }, []);

    return (
        <>
            <Container className="mt-3">
                <h2 className="text-center">Latest Events</h2>
                <ListGroup>
                    {flashnews?.map((event) => (
                        <EventCard key={event.id}>
                            <div className="row">
                                <div className="col-md-9">
                                    <div>
                                        <Title style={{ fontWeight: "bold" }}>{event.title}</Title>
                                        <Date style={{ fontWeight: "bold" }}>
                                            <i className='fa fa-calendar'></i>&nbsp;&nbsp;&nbsp;{event.eventdate}<br />
                                        </Date>
                                        <Content>
                                            <p dangerouslySetInnerHTML={{ __html: event.content }} style={{ textAlign: "justify" }} />
                                        </Content>
                                        <Link>
                                            {event.file_url && (
                                                <>
                                                    <i className='fa fa-file-pdf'></i>&nbsp;&nbsp;&nbsp;
                                                    <a href={event.file_url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "blue" }} download>
                                                        {event.file_url}&nbsp;&nbsp;&nbsp;<i className="fa fa-download"></i>
                                                    </a>
                                                </>
                                            )}
                                        </Link>

                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <img src={event.media_url || 'images/all-img/noimage.png'} style={{ width: "250px", height: "150px" }} alt='events'></img>
                                </div>
                            </div>
                        </EventCard>

                    ))}
                </ListGroup>
                <center><button onClick={goBack} className='btn btn-primary btn-sm mt-4'>Go Back</button></center>
            </Container>
        </>
    )
}

export default FlashNews;
