
import React, { useState, useEffect } from "react";
import axios from "axios";
import Accordion from 'react-bootstrap/Accordion';
import DataTable from "react-data-table-component";
import { ReligioUrl } from "../../components/API/Api";
import CircularProgress from "@mui/material/CircularProgress";

function CouncilMembers() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get(`${ReligioUrl}/council/562`)
            .then((response) => {
                setData(response.data.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
            width: "70px",
        },
        {
            name: "Image",
            cell: (row) => (
                <img
                    src={`${row.image === null
                        ? "images/all-img/Catholic-Priest.png"
                        : row.image
                        }`}
                    alt="Parish"
                    style={{ width: "80px", height: "80px", marginTop: "10px", marginBottom: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", borderRadius: "10px" }}
                />
            ),
            width: "100px",
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.member_name || "N/A",
            sortable: true,
        },
        {
            name: "Role",
            selector: (row) => row.role || "N/A",
            sortable: true,
        },
        {
            name: "Date From",
            selector: (row) => row.date_from || "N/A",
            sortable: true,
        },
        {
            name: "Mobile",
            selector: (row) => row.mobile || "N/A",
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email || "N/A",
            sortable: true,
        }
    ];
    const customStyles = {
        rows: {
            style: {
                minHeight: "52px",
                backgroundColor: "#fafafa",
            },
        },
        headCells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                fontSize: "15px",
                fontFamily: "Montserrat",
                backgroundColor: "#912849",
                color: "white",
            },
        },
        cells: {
            style: {
                paddingLeft: "8px",
                paddingRight: "8px",
                fontFamily: "Montserrat",
                fontSize: "14px",
            },
        },
        pagination: {
            style: {
                fontWeight: "700",
                color: "black",
                fontFamily: "Montserrat",
            },
        },
    };

    return (
        <div className='container'>
            <div className="page-header">
                <h2 className="heading">Council Members</h2>
            </div>
            {loading && (
                <CircularProgress
                    sx={{ display: "block", margin: "auto", marginTop: "2rem" }}
                />
            )}
            <Accordion>
                {data?.map((item, index) => (
                    <>
                        {index > 0 && <div className="mt-3" />}
                        <Accordion.Item eventKey={item.id}>
                            <Accordion.Header>{item?.name} ({item?.members?.length})</Accordion.Header>
                            <Accordion.Body>
                                <DataTable
                                    columns={columns}
                                    theme="solarized"
                                    data={item.members}
                                    pagination
                                    progressPending={false}
                                    customStyles={customStyles}
                                    paginationPerPage={5}
                                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                />
                            </Accordion.Body>
                        </Accordion.Item>
                    </>
                ))}
            </Accordion>
        </div>
    );
}

export default CouncilMembers;
