import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function UniqueStructure() {
  const textColor = '#001c38';
  return (
    <Container className="my-5">
      <div className="row tpn_card">
        <div className="col-lg-4">
          <div className="tpn_card" style={{ height: "98%" }}>
            <img src="images/all-img/bishop1.jpg" className="w-100 mb-4" style={{ height: "400px" }} alt="Project" />
            <p className="text-black">
              <b style={{ color: textColor }}>His Excellency</b>
              <br />
              <b style={{ color: textColor }}>Most Rev. Dr. A. NEETHINATHAN</b>
              <br />
              <b style={{ color: textColor }}>Born :</b> July-03, 1955
              <br />
              <b style={{ color: textColor }}>Feast :</b> June-29
              <br />
              <b style={{ color: textColor }}>Priestly Ordination :</b> May-09, 1987
              <br />
              <b style={{ color: textColor }}>Appointed as Bishop :</b> July-19, 2002
              <br />
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="tpn_card text-center" style={{ height: "98%" }}>
            <Link
              to={"/bishop-profile"}
              className="responsive-link"
              style={{ textDecoration: "none", color: "inherit" }}>
              <img src="images/all-img/homebishop.png" className="w-100 mb-2" style={{ height: "200px" }} alt="Project" />
              <h5 style={{ color: textColor }}>Bishop’s Profile</h5>
            </Link>
            <br />

            <Link
              to={"/bishop-speak"}
              className="responsive-link"
              style={{ textDecoration: "none", color: "inherit" }}>
              <img src="images/all-img/message.jpg" className="w-100 mb-2" style={{ height: "200px" }} alt="Project" />
              <h5 style={{ color: textColor }}>Bishop’s Message</h5>
            </Link>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="tpn_card text-center" style={{ height: "98%" }}>
            <Link
              to={"/bishop-calendar"}
              className="responsive-link"
              style={{ textDecoration: "none", color: "inherit" }}>
              <img src="images/all-img/program.jpg" className="w-100 mb-2" style={{ height: "200px" }} alt="Project" />
              <h5 style={{ color: textColor }}>Monthly Program</h5>
            </Link>
            <br />
            <Link
              to={"/newsletter"}
              className="responsive-link"
              style={{ textDecoration: "none", color: "inherit" }}>
              <img src="images/all-img/newsletter.jpg" className="w-100 mb-2" style={{ height: "200px" }} alt="Project" />
              <h5 style={{ color: textColor }}>News Letters</h5>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default UniqueStructure;
