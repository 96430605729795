import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ApiUrl } from '../../components/API/Api';
import Table from 'react-bootstrap/Table';

const Vicariates = () => {
  const [vicariate, setVicariate] = useState([]);

  useEffect(() => {
    axios.get(`${ApiUrl}/get/chengaidiocese/Vicariates`).then((response) => {
      setVicariate(response.data);
    });
  }, []);

  return (
    <>
      <div className="container">
        <h3 className="text-center mt-4 mb-4">Vicariates</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.No</th>
              <th>Vicariate Name</th>
            </tr>
          </thead>
          <tbody>
            {vicariate.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.vicariate}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default Vicariates

