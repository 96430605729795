import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { ApiUrl } from '../../components/API/Api';

// Utility function to map month number to name
const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('default', { month: 'long' });
};

function NewsLetter() {
    const [pdfLinks, setPdfLinks] = useState([]);
    const [archivedPdfLinks, setArchivedPdfLinks] = useState({});
    const [mainAccordionOpen, setMainAccordionOpen] = useState(true);
    const [subAccordionOpen, setSubAccordionOpen] = useState({});

    const handleMainAccordionClick = () => {
        setMainAccordionOpen((prevState) => !prevState);
        // Toggle sub-accordion for the current year
        setSubAccordionOpen((prevState) => ({
            ...prevState,
            [new Date().getFullYear()]: !prevState[new Date().getFullYear()],
        }));
    };

    const handleSubAccordionClick = (year) => {
        setSubAccordionOpen((prevState) => ({
            ...prevState,
            [year]: !prevState[year],
        }));
    };

    const handleMouseEnter = (event) => {
        event.currentTarget.style.overflowY = "scroll";
    };

    const handleMouseLeave = (event) => {
        event.currentTarget.style.overflowY = "hidden";
    };

    useEffect(() => {
        axios
            .get(`${ApiUrl}/get/Newsletter`)
            .then((response) => {
                const allPdfLinks = response?.data?.data?.sort((a,b) => b?.id - a?.id);
                const currentYearPdfLinks = [];
                const archivedPdfLinks = {};

                const currentYear = new Date().getFullYear();

                allPdfLinks.forEach((item) => {
                    const year = item.year;
                    const monthName = getMonthName(item.month);
                    if (year < currentYear) {
                        if (!archivedPdfLinks[year]) {
                            archivedPdfLinks[year] = [];
                        }
                        archivedPdfLinks[year].push({ ...item, monthName });
                    } else {
                        currentYearPdfLinks.push({ ...item, monthName });
                    }
                });

                setPdfLinks(currentYearPdfLinks);
                setArchivedPdfLinks(archivedPdfLinks);
                const defaultSubAccordionState = {};
                Object.keys(archivedPdfLinks).forEach((year) => {
                    defaultSubAccordionState[year] = false;
                });
                setSubAccordionOpen(defaultSubAccordionState);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    if (pdfLinks.length > 0) {
        pdfLinks.sort((a, b) => new Date(b.year, b.month - 1) - new Date(a.year, a.month - 1));
    }

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="container subpage">
                <br />
                <h2>Monthly News Letter</h2>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div
                                className="col-12 col-lg-12 custom-scrollbar"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}>
                                <div className="accordion" id="newsletterAccordion">
                                    <div className="accordion-item mb-4">
                                        <h2 className="accordion-header" onClick={handleMainAccordionClick}>
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#allNewsletters"
                                                aria-expanded={mainAccordionOpen ? "true" : "false"}
                                                aria-controls="allNewsletters"
                                                style={{ fontWeight: "bold", backgroundColor: "#d4ad5f" }}>
                                                NewsLetter - {new Date().getFullYear()}
                                            </button>
                                        </h2>
                                        <div
                                            id="allNewsletters"
                                            className={`accordion-collapse collapse ${mainAccordionOpen ? "show" : ""}`}
                                            aria-labelledby="allNewsletters"
                                            data-parent="#newsletterAccordion">
                                            <div className="accordion-body">
                                                {pdfLinks.length > 0 ? (
                                                    pdfLinks
                                                        .reduce((acc, pdf) => {
                                                            const existingMonth = acc.find(
                                                                (item) => item.monthName === pdf.monthName
                                                            );
                                                            if (existingMonth) {
                                                                existingMonth.pdfs.push(pdf);
                                                            } else {
                                                                acc.push({ monthName: pdf.monthName, pdfs: [pdf] });
                                                            }
                                                            return acc;
                                                        }, [])
                                                        .map((monthGroup, index) => (
                                                            <div key={index}>
                                                                <div className="accordion-item">
                                                                    <h2
                                                                        className="accordion-header"
                                                                        onClick={() =>
                                                                            handleSubAccordionClick(index + 1)
                                                                        }>
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse${index}`}
                                                                            aria-expanded={subAccordionOpen === index + 1 ? "true" : "false"}
                                                                            aria-controls={`collapse${index}`}
                                                                            onClick={(event) => {
                                                                                const clickedElement = event.target;
                                                                                const isSubAccordion = clickedElement.closest(
                                                                                    ".accordion-collapse"
                                                                                );

                                                                                if (!isSubAccordion) {
                                                                                    const mainAccordion = document.getElementById(
                                                                                        "allNewsletters"
                                                                                    );
                                                                                    if (mainAccordion) mainAccordion.classList.remove("show");
                                                                                }
                                                                            }}
                                                                            style={{ fontWeight: "bold" }}>
                                                                            {monthGroup.eventdate} {currentYear}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapse${index}`}
                                                                        className={`accordion-collapse collapse ${subAccordionOpen === index + 1 ? "show" : ""
                                                                            }`}
                                                                        aria-labelledby={`heading${index}`}
                                                                        data-parent="#newsletterAccordion">
                                                                        <div className="accordion-body">
                                                                            {monthGroup.pdfs.map((pdf, pdfIndex) => (
                                                                                <div key={pdfIndex}>
                                                                                    <p>
                                                                                        <b>{pdf.title} </b>
                                                                                    </p>
                                                                                    <p>{`${pdf.eventdate}`}</p>
                                                                                    <a
                                                                                        href={pdf.file_url}
                                                                                        rel="noopener noreferrer"
                                                                                        className="btn btn-sm"
                                                                                        target="_blank"
                                                                                        style={{
                                                                                            backgroundColor: "#012c6d",
                                                                                            color: "#e0ab08",
                                                                                            textDecoration: "none",
                                                                                        }}>
                                                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                                                    </a>
                                                                                    <br />
                                                                                    <br />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        ))
                                                ) : (
                                                    <p className="text-center font-weight-bold" style={{ fontSize: "16px" }}>
                                                        No Newsletters Available
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Archived Newsletters */}
                                    {Object.keys(archivedPdfLinks)
                                        .sort((a, b) => b - a)
                                        .filter((year) => year < currentYear)
                                        .map((year, yearIndex) => (
                                            <div key={yearIndex} className="accordion-item mb-4">
                                                <h2 className="accordion-header" onClick={() => handleSubAccordionClick(yearIndex + 1)}>
                                                    <button
                                                        className="accordion-button"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#archiveNewsletters${yearIndex}`}
                                                        aria-expanded={subAccordionOpen[year]}
                                                        aria-controls={`archiveNewsletters${yearIndex}`}
                                                        style={{ fontWeight: "bold", backgroundColor: "#d4ad5f" }}>
                                                        Archived NewsLetter - {year}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`archiveNewsletters${yearIndex}`}
                                                    className={`accordion-collapse collapse ${subAccordionOpen === yearIndex + 1 ? "show" : ""}`}
                                                    aria-labelledby={`archiveNewsletters${yearIndex}`}
                                                    data-parent="#newsletterAccordion">
                                                    <div className="accordion-body">
                                                        {archivedPdfLinks[year] && archivedPdfLinks[year].length > 0 ? (
                                                            archivedPdfLinks[year].map((pdf, pdfIndex) => (
                                                                <div key={pdfIndex}>
                                                                    <p>
                                                                        <b>{pdf.title} </b>
                                                                    </p>
                                                                    <p>{`${pdf.eventdate}`}</p>
                                                                    <a
                                                                        href={pdf.file_url}
                                                                        rel="noopener noreferrer"
                                                                        className="btn btn-sm"
                                                                        target="_blank"
                                                                        style={{
                                                                            backgroundColor: "#012c6d",
                                                                            color: "#e0ab08",
                                                                            textDecoration: "none",
                                                                        }}>
                                                                        <FontAwesomeIcon icon={faDownload} /> Download
                                                                    </a>
                                                                    <br />
                                                                    <br />
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className="text-center font-weight-bold" style={{ fontSize: "16px" }}>
                                                                No Archived Newsletters Available for {year}
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsLetter;
