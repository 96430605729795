import React from "react";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReligioUrl } from "../../components/API/Api";
import axios from "axios";
import { FaEye } from "react-icons/fa";

const Diocese = () => {
  const navigate = useNavigate();
  const [data, Setdata] = useState([]);
  const [filterddata, Filterdata] = useState([]);

  useEffect(() => {
    axios
      .get(`${ReligioUrl}/member/diocese_priest/562`)
      .then((response) => {
        Setdata(response?.data?.data);
        Filterdata(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      width: "70px",
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${row.image === null
            ? "images/all-img/Catholic-Priest.png"
            : row.image
            }`}
          alt="Priestimage"
          style={{ width: "80px", height: "87px", marginTop: "10px", marginBottom: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",borderRadius: "10px" }}
        />
      ),
      width: "100px",
    },
    {
      name: "Priest Name",
      selector: (row) => row.member_name || "-",
      sortable: true,
    },
    {
      name: "Date of Birth",
      selector: (row) => row.dob || "-",
      sortable: true,
    },
    {
      name: "Ordination Date",
      selector: (row) => row.ordination_date || "-",
      sortable: true,
    },
    {
      name: "Role",
      cell: (row) => (
        <>
          {row.role_ids?.map((role, index) => (
            <span key={index} className="badge badge-pill me-1" style={{ backgroundColor: index % 2 === 0 ? "#198754" : "red" }}>
              {role}
            </span>
          )) || <span className="badge badge-pill bg-success me-1">-</span>}
        </>
      ),
      sortable: true,
    },
    {
      name: "Place",
      selector: (row) => row.ministry || "-",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <div
            onClick={(e) => viewDiocesepreiest(row.id)}
            style={{ cursor: "pointer", paddingRight: 4, color: "black", textDecoration: "none" }}
            role="button"
            tabIndex="0"
          >
            <FaEye /> View
          </div>
        </>
      ),

      width: "100px",
    },
  ];
  const customStyles = {
    rows: {
      style: {
        minHeight: "52px",
        backgroundColor: "#fafafa",
      },
    },
    headCells: {
      style: {
        paddingLeft: "7px",
        paddingRight: "7px",
        fontSize: "15px",
        fontFamily: "Montserrat",
        backgroundColor: "#912849",
        color: "white",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
        fontFamily: "Montserrat",
        fontSize: "14px",
      },
    },
    pagination: {
      style: {
        fontWeight: "700",
        color: "black",
        fontFamily: "Montserrat",
      },
    },
  };
  function filterdata(event) {
    var value = event.target.value;
    const keys = [
      "name",
      "date_of_ordination",
      "date_of_birth",
      "ministry",
      "email",
    ];
    const filter = filterddata?.filter((item) =>
      keys.some((key) =>
        item[key]?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      )
    );

    Setdata(filter);
  }
  const viewDiocesepreiest = async (id) => {
    navigate(`/priestmoredetails?di=${id}`);
  };
  const [pending, setPending] = React.useState(true);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className="container">
      <div className="page-header">
        <h2 class="heading">Diocese Priest</h2>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <input
            id="myInput"
            type="text"
            onChange={filterdata}
            className="form-control myInput"
            placeholder="Search.."
          />
        </div>
      </div>
      <br></br>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                theme="solarized"
                data={data}
                pagination
                progressPending={pending}
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                noHeader
                responsive
                striped
                paginationRowsPerPageOptions={[10, 30, 60, 90, 120, 500]}
                paginationPerPage={10}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Diocese;
