import React, { useState, useEffect } from "react";
import axios from "axios";
import { ApiUrl } from "../../components/API/Api";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";

const Administration = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [id, setPageTitle] = useState("");
  let location = useLocation();
  const url = location.pathname;

  useEffect(() => {
    const path = {
      "/tribunal": 14,
      "/finance": 15,
      "/judicial-vicar": 16,
      "/tribunal-activity": 17,
      "/college-consult": 18,
      "/diocesan-priest-council": 19,
      "/diocesan-finance-committee": 20,
      "/diocesan-property-committee": 21,
      "/diocesan-project-committee": 22,
      "/council-pastroal": 23,
      "/council-formation": 24,
      "/diocesan-society": 25,
      "/chingleput-education-society": 26,
      "/chingleput-development": 27,
    };
    setPageTitle(path[url] ? path[url] : url);
  }, [url]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const condition = localStorage.getItem("Pages");
        if (condition) {
          setData(JSON.parse(condition));
          setLoading(false);
        } 
          const response = await axios.get(`${ApiUrl}/get/Pages`);
          const newData = response?.data?.data;
          const exixtingdate = localStorage.getItem("Pages");
          if (newData !== JSON.stringify(exixtingdate)) {
            localStorage.removeItem("Pages");
            localStorage.setItem("Pages", JSON.stringify(newData));
            setData(newData);
          }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <CircularProgress
        sx={{ display: "block", margin: "auto", marginTop: "5em" }}
      />
    );
  }

  if (!data || data.length === 0) {
    return (
      <div className="text-center mt-5">
        <b>No Data Available</b>
      </div>
    );
  }

  const filteredData = data.filter((item) => item.id === id);

  if (filteredData.length === 0) {
    return (
      <div className="text-center mt-5">
        <b>Under Construction</b>
      </div>
    );
  }

  return (
    <>
      <div className="container subpage">
        <div className="row">
          <div className="col-lg-12">
            {filteredData.map((item) => (
              <div key={item.id}>
                <h2 className="heading">{item.title}</h2>
                <div
                  style={{ textAlign: "justify" }}
                  dangerouslySetInnerHTML={{
                    __html: `<style>table { width: 100%; max-width: 100%; border-collapse: collapse; } table, th, td { border: 1px solid #ddd; padding: 8px; text-align: left; } @media (max-width: 600px) { table, th, td { display: block; width: 100%; box-sizing: border-box; } } </style>${item.content}`,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Administration;
