import React from 'react';
import './News.css';
import { Link } from 'react-router-dom';

const News = ({ projectdata }) => {

  const newsdata = projectdata.filter(project => project.category_id === 1);

  const filterdata = newsdata.slice(0, 4);

  if (!filterdata) {
    return (
      <div className='container'>
        <div className="section-heading">
          <h2 className="entry-title">News & Events</h2>
          <div><b>Loading...</b></div>
        </div>
      </div>
    );
  }

  return (
    <div className='container'>
      <div className="section-heading">
        <h2 className="entry-title">News & Events</h2>
      </div>
      <div className="row mt-2">
        {filterdata?.map((project, index) => (
          <div className="col-md-3" key={index}>
            <div className="tpn_card" id='news-back'>
              <img src={project?.media_url || 'images/all-img/noimage.png'} className="w-100 mb-4" alt="no data" />
              <h6>{project?.title}</h6>
              <p>{project?.eventdate}</p>
              <Link  // to={`latestevents?newsid=${newsItem?.id}`}
                to={`flash-news-data`}>
                <button className="brown-button btn-sm">Read More</button>
              </Link>
            </div>
          </div>
        ))}
        {
          filterdata?.length === 0 && <p><b>No News Available</b></p>
        }
      </div>
    </div>
  );
}

export default News;
