import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ReligioUrl } from '../../../components/API/Api';
import { CircularProgress } from '@mui/material';

const AllPriestDetails = () => {
  const search = useLocation().search;
  const PriestID = new URLSearchParams(search).get("di");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [priestData, setPriestData] = useState(null);

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ReligioUrl}/member/diocese_priest/562`);
        const data = response?.data?.data;
        setPriestData(data);
        if (data && PriestID) {
          const foundPriest = data.find(item => item.id === parseInt(PriestID));
          setPriestData(foundPriest);
        }
        console.log(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [PriestID]);

  const downloadPdf = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
    });

    const header = "Priest Details";
    pdf.setFontSize(20);
    pdf.text(header, pdf.internal.pageSize.getWidth() / 2, 15, "center");

    const filename = `${priestData.member_name}.pdf`;

    const tableData = [
      ["Priest Type", priestData.member_type],
      ["Name", priestData.member_name],
      ["Date of Birth", priestData.dob],
      [
        "Ordination Date",
        priestData.ordination_date,
      ],
      ["Blood Group", priestData.blood_group],
      ["Address", priestData.address],
      ["Email", priestData.email],
      ["Phone Number", priestData.mobile],
      ["Ministry", priestData.ministry],
      ["Role", priestData.role_ids],
    ];

    // Set page border
    pdf.setLineWidth(1);
    pdf.rect(
      5,
      5,
      pdf.internal.pageSize.getWidth() - 10,
      pdf.internal.pageSize.getHeight() - 10
    );

    pdf.autoTable({
      startY: 30,
      head: [["Field", "Details"]],
      body: tableData,
      theme: "grid",
      styles: {
        fontSize: 12,
        cellPadding: 5,
        overflow: "linebreak",
      },
      columnStyles: {
        0: { fontStyle: "bold", fillColor: "#f2f2f2" },
        1: {},
      },
    });

    const footer = "Copyright © Diocese of Chingleput";
    const printDateTime = `Printed on: ${new Date().toLocaleString()}`;
    pdf.setFontSize(10);
    pdf.text(printDateTime, 10, pdf.internal.pageSize.getHeight() - 10);
    pdf.text(
      footer,
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() - 10,
      "center"
    );

    pdf.save(filename);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <div><center><p className="mt-5"><CircularProgress /></p></center></div>;
  }

  if (!priestData) {
    return <div><center><p className="mt-5">No data available</p></center></div>;
  }

  return (
    <>
      <div className="container mt-5">
        {priestData && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <h4
                    className="heading text-left mb-4 ml-0"
                    style={{ fontFamily: "Montserrat" }}>
                    {priestData.member_name}
                  </h4>
                  <dl className="row">
                    <div className="col-sm-6">
                      <dt>Type :</dt>
                      <dd>{priestData.member_type}</dd>

                      <dt>Priest Name :</dt>
                      <dd>{priestData.member_name || "No Name Available"}</dd>

                      <dt>DOB :</dt>
                      <dd>
                        {priestData.dob || "No Date of Birth Available"}
                      </dd>

                      <dt>Role :</dt>
                      <dd>
                        {priestData?.role_ids?.map((role, index) => (
                          <span key={index} className="badge badge-pill badge-success me-1">
                            {role || "No Role"}
                          </span>
                        ))}
                      </dd>

                      <dt>Ordination Date :</dt>
                      <dd>
                        {priestData.ordination_date}
                      </dd>

                    </div>

                    <div className="col-sm-6">

                      <dt>Blood Group :</dt>
                      <dd>{priestData.blood_group}</dd>

                      <dt>Address :</dt>
                      <dd>{priestData.address}</dd>

                      <dt>Email :</dt>
                      <dd>
                        <a
                          href={`mailto:${priestData.email}`}
                          style={{ textDecoration: "none" }}>
                          {priestData.email}
                        </a>
                      </dd>

                      <dt>Phone Number :</dt>
                      <dd>
                        <a
                          href={`tel:${priestData.mobile}`}
                          style={{ textDecoration: "none" }}>
                          {priestData.mobile}
                        </a>
                      </dd>

                      <dt>Ministry :</dt>
                      <dd>{priestData.ministry}</dd>
                    </div>
                  </dl>
                  <button
                    className="btn btn-primary btn-sm"
                    title="Go Back"
                    onClick={goBack}>
                    <FaArrowLeft />
                  </button>
                  <button
                    onClick={downloadPdf}
                    className="btn btn-success btn-sm"
                    style={{ marginLeft: "10px" }}
                    title="Download PDF">
                    <FaFileDownload />
                  </button>
                </div>

                <div className="col-lg-3 text-center">
                  <img
                    src={priestData.image || "images/all-img/Catholic-Priest.png"}
                    alt="Priest"
                    className="img-fluid mt-4"
                    width="100"
                    height="150"
                    style={{marginTop: "10px", marginBottom: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",borderRadius: "10px"}}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AllPriestDetails;
