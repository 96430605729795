import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { FaArrowLeft, FaFileDownload } from "react-icons/fa";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ReligioUrl } from '../../../components/API/Api';
import { CircularProgress } from '@mui/material';

const NewParishesDetails = () => {
  const search = useLocation().search;
  const ParishID = new URLSearchParams(search).get("di");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ParishData, setParishData] = useState(null);

  const goBack = () => {
    window.history.back();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${ReligioUrl}/diocese/parish/562`);
        const data = response?.data?.data;
        setParishData(data);
        if (data && ParishID) {
          const foundParish = data.find(item => item.id === parseInt(ParishID));
          setParishData(foundParish);
        }
        console.log(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [ParishID]);

  const downloadPdf = () => {
    const pdf = new jsPDF({
      orientation: "portrait",
    });

    const header = "Parish Details";
    pdf.setFontSize(20);
    pdf.text(header, pdf.internal.pageSize.getWidth() / 2, 15, "center");

    const filename = `${ParishData.name}.pdf`;

    const tableData = [
      ["Parish Name", ParishData.name || "-"],
      ["Run By", ParishData.run_by || "-"],
      ["Patron", ParishData.patron || "-"],
      [
        "Shrine Name",
        ParishData.shrine || "-"
      ],
      ["Established Date", ParishData.establishment_date || "-"],
      ["Vicariate Name", ParishData.vicariate_name || "-"],
      ["Parish Priest Name", ParishData.parish_priest || "-"],
      ["Asst. Priest Name", ParishData.ass_priest_id || "-"],
      ["Email", ParishData.email || "-"],
      ["Phone Number", ParishData.phone || "-"],
      ["Address", ParishData.address || "-"],
    ];

    // Set page border
    pdf.setLineWidth(1);
    pdf.rect(
      5,
      5,
      pdf.internal.pageSize.getWidth() - 10,
      pdf.internal.pageSize.getHeight() - 10
    );

    pdf.autoTable({
      startY: 30,
      head: [["Field", "Details"]],
      body: tableData,
      theme: "grid",
      styles: {
        fontSize: 12,
        cellPadding: 5,
        overflow: "linebreak",
      },
      columnStyles: {
        0: { fontStyle: "bold", fillColor: "#f2f2f2" },
        1: {},
      },
    });

    const footer = "Copyright © Diocese of Chingleput";
    const printDateTime = `Printed on: ${new Date().toLocaleString()}`;
    pdf.setFontSize(10);
    pdf.text(printDateTime, 10, pdf.internal.pageSize.getHeight() - 10);
    pdf.text(
      footer,
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() - 10,
      "center"
    );

    pdf.save(filename);
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (loading) {
    return <div><center><p className="mt-5"><CircularProgress /></p></center></div>;
  }

  if (!ParishData) {
    return <div><center><p className="mt-5">No data available</p></center></div>;
  }

  return (
    <>
      <div className="container mt-5">
        {ParishData && (
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-9">
                  <h4
                    className="heading text-left mb-4 ml-0"
                    style={{ fontFamily: "Montserrat" }}>
                    {ParishData.name}
                  </h4>
                  <dl className="row">
                    <div className="col-sm-6">

                      <dt>Parish Name :</dt>
                      <dd>{ParishData.name || "-"}</dd>

                      <dt>Run By :</dt>
                      <dd>
                        {ParishData.run_by || "-"}
                      </dd>

                      <dt>Patron :</dt>
                      <dd>
                        {ParishData.patron || "-"}
                      </dd>

                      <dt>Shrine :</dt>
                      <dd>
                        {ParishData.shrine || "-"}
                      </dd>

                      <dt>Established Date :</dt>
                      <dd>{ParishData.establishment_date || "-"}</dd>

                      <dt>Vicariate Name :</dt>
                      <dd>{ParishData.vicariate_name || "-"}</dd>

                    </div>

                    <div className="col-sm-6">

                      <dt>Parish Priest :</dt>
                      <dd>{ParishData.parish_priest}</dd>

                      <dt>Asst. Parish Priest :</dt>
                      <dd>{ParishData.ass_priest_id}</dd>

                      <dt>Email :</dt>
                      <dd>
                        <a
                          href={`mailto:${ParishData.email}`}
                          style={{ textDecoration: "none" }}>
                          {ParishData.email || "-"}
                        </a>
                      </dd>

                      <dt>Phone Number :</dt>
                      <dd>
                        <a
                          href={`tel:${ParishData.phone}`}
                          style={{ textDecoration: "none" }}>
                          {ParishData.phone || "-"}
                        </a>
                      </dd>

                      <dt>Address :</dt>
                      <dd>{ParishData.address}</dd>
                    </div>
                  </dl>
                  <button
                    className="btn btn-primary btn-sm"
                    title="Go Back"
                    onClick={goBack}>
                    <FaArrowLeft />
                  </button>
                  <button
                    onClick={downloadPdf}
                    className="btn btn-success btn-sm"
                    style={{ marginLeft: "10px" }}
                    title="Download PDF">
                    <FaFileDownload />
                  </button>
                </div>

                <div className="col-lg-3 text-center">
                  <img
                    src={ParishData.image || "images/all-img/Catholic-Priest.png"}
                    alt="Priest"
                    className="img-fluid mt-4"
                    width="100"
                    height="150"
                    style={{ marginTop: "10px", marginBottom: "10px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", borderRadius: "10px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewParishesDetails;
