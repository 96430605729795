import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { ApiUrl } from "../../components/API/Api";

const Contact = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

 
  const [loaderVisible, setLoaderVisible] = useState(false);

  const recaptchaRef = useRef();



  const onSubmitContactForm = (data, e) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setValue("recaptcha", "", { shouldValidate: true });
      setLoaderVisible(false);
      return;
    }

    Swal.fire(
      "Thank you for contacting us. We will get in touch with you shortly.",
      "",
      "success"
    );
    e.target.reset();

    // setLoaderVisible(true);

    data.recaptchaValue = recaptchaValue;

    axios
      .post(`${ApiUrl}/store/contact`, data)
      .then(() => {
        // setLoaderVisible(false);
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: err.message,
        });
      });
  };
  return (
    <>
      <div className="container subpage">
        <h3 className="heading">Contact Us</h3>
        <div className="row">
          <div className=" col-lg-6">
            <div className="card shadow">
              <div className="card-body">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1945.9603206740717!2d79.955738!3d12.7186!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52fb747a9461e7%3A0xd13787ec158b0013!2sChengalpattu%20Catholic%20Bishop%20House!5e0!3m2!1sen!2sin!4v1710424391721!5m2!1sen!2sin"
                  height={270}
                  style={{ border: 0, width: " 100%" }}
                  allowFullScreen
                  title="salemdiocese"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
                <div>
                  <i className="fa fa-map-marker"></i>&nbsp;&nbsp;&nbsp; Diocese
                  of Chingleput
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Catholic Bishop’s
                  House,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thimmavaram, Kancheepuram
                  Road
                  <br /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chingleput – 603
                  101
                </div>
                <br />
                <div>
                  <i className="fa fa-phone"></i>&nbsp;&nbsp;
                  <a href="tel:+91 044–27426649" style={{ color: "#6b1d2f" }}>
                    044 – 27 42 66 49
                  </a>
                </div>
                <br />

                <div>
                  <i className="fa fa-envelope"></i>&nbsp;&nbsp;
                  <a
                    href="mailto:chancellorchengai@gmail.com"
                    style={{ color: "#6b1d2f" }}>
                    chancellorchengai@gmail.com
                  </a>
                </div>
                <br />

                <div>
                  <i className="fa fa-globe"></i>&nbsp;&nbsp;
                  <a
                    href="https://chengaidiocese.org/"
                    style={{ color: "#6b1d2f" }}>
                    https://chengaidiocese.org
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="card shadow">
              <div className="card-body">
                <form
                  className="php-email-form"
                  onSubmit={handleSubmit(onSubmitContactForm)}>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="name">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        {...register("name", { required: true })}
                        aria-invalid={errors?.name ? "true" : "false"}
                      />
                      {errors?.name?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Name is required</label>
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="email">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        {...register("email", { required: true })}
                        aria-invalid={errors?.email ? "true" : "false"}
                      />
                      {errors?.email?.type === "required" && (
                        <div className="text-danger text_error">
                          <label className="errlabel">Email is required</label>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Your Mobile</label>
                    <input
                      type="text"
                      className="form-control"
                      name="mobile"
                      id="mobile"
                      maxLength={10}
                      {...register("mobile", { required: true })}
                      aria-invalid={errors?.mobile ? "true" : "false"}
                    />
                    {errors?.mobile?.type === "required" && (
                      <div className="text-danger text_error">
                        <label className="errlabel">Mobile is required</label>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      name="message"
                      rows={5}
                      defaultValue={""}
                      {...register("message", { required: true })}
                      aria-invalid={errors?.message ? "true" : "false"}
                    />
                    {errors?.message?.type === "required" && (
                      <div className="text-danger text_error">
                        <label className="errlabel">Message is required</label>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="14841bcb-d637-4cfb-a6c3-96c8bdd890c4"
                      onChange={(value) => {
                        setValue("recaptcha", value, { shouldValidate: true });
                      }}
                      style={{ maxWidth: "300px", margin: "0 auto" }}
                    />
                    {errors.recaptcha && (
                      <span className="text-danger">
                        Please complete the reCAPTCHA verification.
                      </span>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" className="buttonjs">
                      Send Message
                    </button>

                    <span
                      id="loader"
                      style={{
                        display: loaderVisible ? "inline-block" : "none",
                      }}>
                      <img
                        src="images/subpage/gif/ajaxload.gif"
                        width="32px"
                        height="32px"
                        alt="Loader"
                      />
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
