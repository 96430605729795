import React from "react";
import { Link } from "react-router-dom";

function About() {
  return (
    <>
      {/* <div className="home-page-welcome">
        <div className="row welcomesection">
          <div className="col-lg-5"></div>
          <div className="col-lg-7">
            <div className="container">
              <h2 className="text-white">
                <b>WELCOME TO THE DIOCESE OF CHINGLEPUT</b>
              </h2>
              <p
                className="text-white"
                style={{ textAlign: "justify", lineHeight: "2rem" }}>
                The Diocese of Chingleput was declared as an independent new
                Diocese on 19th July 2002. Most Rev. Dr. A. Neethinathan was
                appointed as the first Bishop of Chingleput. The Diocese of
                Chinglput comprises of the entire civil districts of
                Chengalpatu, Kancheepuram and some part of the district of
                Chennai. It has 107 parishes and three Independent Shrines. 80%
                of parishes are in the rural areas.146 diocesan priests, 70
                religious priests and 72 religious congregations serve in the
                diocese.
              </p>
              <Link to="/history">
                <button className="brown-button">Read More</button>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
      <div className="home-page-welcome">
        <div className="container">
          <div className="row mt-4">
            <div className="col-12 col-lg-12 order-2 order-lg-1">
              <div className="welcome-content">
                <header className="entry-header">
                  <h2 className="entry-title">
                  WELCOME TO THE DIOCESE OF CHINGLEPUT
                  </h2>
                </header>

                <div className="entry-content mt-2 text-justify ">
                  <p>The Diocese of Chingleput was declared as an independent new
                Diocese on 19th July 2002. Most Rev. Dr. A. Neethinathan was
                appointed as the first Bishop of Chingleput. The Diocese of
                Chinglput comprises of the entire civil districts of
                Chengalpatu, Kancheepuram and some part of the district of
                Chennai. It has 107 parishes and three Independent Shrines. 80%
                of parishes are in the rural areas.146 diocesan priests, 70
                religious priests and 72 religious congregations serve in the
                diocese.</p>   
                </div>
                <center>
                  <Link to="/history">
                    <button className="brown-button btn-sm">Read More</button>
                  </Link>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
